body {
	font-family: Lato, sans-serif;
	color: #333;
	font-size: 14px;
	line-height: 1.3;
	background-color: #1a3354;
}

h1 {
	margin-top: 0.625rem;
	color: #1a3354;
	font-size: 2.3rem;
	line-height: 1.3;
}

h2 {
	margin-top: 0.625rem;
	color: #1a3354;
	font-size: 1.8rem;
	line-height: 1.3;
}

h3 {
	margin-top: 0.625rem;
	color: #1a3354;
	font-size: 1.45rem;
	line-height: 1.4;
}

h4 {
	margin-top: 0.625rem;
	color: #1a3354;
	font-size: 1.125rem;
	line-height: 1.4;
}

h5 {
	margin-top: 0.625rem;
	color: #1a3354;
	font-weight: 900;
	font-size: 0.875rem;
	line-height: 1.5;
}

h6 {
	margin-top: 0.625rem;
	color: #1a3354;
	font-weight: 900;
	font-size: 0.75rem;
	line-height: 1.5;
}

hr {
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	clear: both;
	border: 0;
	border-bottom: 1px solid #c9c6c4;
}

.main-content-column p {
	font-size: 1.125rem;
}

.main-content-column a {
	border-bottom: 2px solid #ffda28;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #004e59;
	line-height: 1rem;
	text-decoration: none;
}

.main-content-column a:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
	color: #1a3354;
}

.main-content-column a.w--current {
	color: #28313b;
	font-weight: 700;
}

.main-content-column h1 a,
.main-content-column h2 a,
.main-content-column h3 a,
.main-content-column h4 a,
.main-content-column h5 a,
.main-content-column h6 a {
	border-bottom: 4px solid #ffda28;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #1a3354;
	text-decoration: none;
}

.main-content-column h3 a,
.main-content-column h4 a,
.main-content-column h5 a,
.main-content-column h6 a {
	border-bottom: 3px solid #ffda28;
}

.main-content-column h1 a:hover,
.main-content-column h2 a:hover,
.main-content-column h3 a:hover,
.main-content-column h4 a:hover,
.main-content-column h5 a:hover,
.main-content-column h6 a:hover {
	border-bottom-color: transparent;
	color: #004e59;
}

.main-content-column-nested {
	font-size: 1rem;
}

img {
	height: auto;
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
}

blockquote {
	width: auto;
	margin-top: 20px;
	padding-top: 20px;
	padding-bottom: 20px;
	border-left-color: #ffda28;
	background-color: #1a3354;
	color: #fff;
	line-height: 25px;
}

blockquote > *:last-of-type {
	margin: 0;
}

/* session messages */
#session-message-wrapper .success {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	border: 2px solid #48a700;
	background-color: #caf3aa;
	color: #000;
	font-size: 1.125rem;
}

#session-message-wrapper .error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	border: 2px solid #721926;
	background-color: #ffccd0;
	color: #000;
	font-size: 1.125rem;
	font-weight: 400;
}

.success :last-child,
.error :last-child {
	margin-bottom: 0;
}
/* end session messages */

/* styled tables */
.styled {
	width: 100%;
	margin: 0 0 0.75rem 0;
	background-color: white;
	border-spacing: 0px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 0px;
	line-height: 1.125em;
}

.styled th,
.styled td {
	border-right: 1px solid rgba(0, 0, 0, 0.15);
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	vertical-align: top;
}

.styled tr th:last-child,
.styled tr td:last-child {
	border-right: none;
}

.styled thead tr th {
	font-weight: 400;
	text-transform: none;
	letter-spacing: 0.02em;
	color: white;
	background-color: #1a3354;
	border-bottom: 1px solid white;
	padding: 0.875em 0.625rem;
	text-align: left;
}

.styled th {
	font-weight: 300;
	padding: 0.625em 0.625rem;
	background-color: #ffda28;
	border-bottom: 0px solid white;
	color: #1a3354;
	text-align: left;
	font-weight: bold;
}

.styled th a {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border: 0;
}

.styled th a:hover {
	color: inherit;
	font-weight: inherit;
	text-decoration: none;
}

.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.9375em;
	line-height: 1.4125em;
}

.styled.striped tr:nth-child(even) {
	background: #eee;
}

.styled.striped tr:nth-child(odd) {
	background: white;
}
/* end styled tables */

/* buttons */
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
button[type="submit"],
.button-5,
.primary,
.secondary,
.tertiary,
a.primary,
a.secondary,
a.tertiary {
	display: inline-block;
	min-width: 100px;
	margin-top: 1rem;
	margin-right: 1rem;
	margin-bottom: 0.5rem;
	padding: 9px 15px;
	border-style: solid;
	border-width: 1px;
	border-color: #004e59;
	border-radius: 20px;
	background-color: #004e59;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #fff;
	font-size: 12px;
	line-height: 20px;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
button[type="submit"]:hover,
.button-5:hover,
.primary:hover,
.secondary:hover,
.tertiary:hover,
a.primary:hover,
a.secondary:hover,
a.tertiary:hover {
	border-style: solid;
	border-width: 1px;
	border-color: #004e59;
	background-color: transparent;
	color: #004e59;
}

.secondary,
a.secondary,
.button-5.secondary {
	border-style: solid;
	border-width: 1px;
	border-color: #1a3354;
	background-color: #fff;
	color: #1a3354;
}

.secondary:hover,
a.secondary:hover,
.button-5.secondary:hover {
	background-color: #1a3354;
	color: #fff;
}

.tertiary,
a.tertiary,
.button-5.tertiary {
	min-width: 0px;
	padding: 0.25rem 0.75rem;
	border-style: solid;
	border-width: 1px;
	border-color: #1a3354;
	background-color: #fff;
	color: #1a3354;
	font-size: 12px;
}

.tertiary:hover,
a.tertiary:hover,
.button-5.tertiary:hover {
	background-color: rgba(0, 0, 0, 0.05);
}
/* end buttons */

/*forms*/
.cms_label,
.label,
label {
	font-weight: 400;
}

label {
	display: inline;
	margin-bottom: 0;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="search"],
textarea,
select {
	font-size: 0.875rem;
	line-height: 1.42857143;
	padding: 8px 12px;
	color: #333;
	border: 1px solid #888888;
}

textarea {
	resize: vertical;
}
/* end of forms */

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
}

#printHeader,
#printFooter {
	display: none;
}

.nav-link {
	padding-top: 30px;
	padding-bottom: 30px;
	font-family: Montserrat, sans-serif;
	color: #004e59;
	font-weight: 700;
}

.slider-arrow {
	left: 0%;
	top: auto;
	right: auto;
	bottom: 0%;
	margin-bottom: 10px;
	margin-left: 10px;
	padding-left: 0px;
	opacity: 1;
	color: #ffda28;
	font-size: 30px;
}

.slider {
	width: 1300px;
	height: 750px;
	max-width: 1300px;
}

.slider-arrow-right {
	left: auto;
	top: auto;
	right: 0%;
	bottom: 0%;
	margin-right: 10px;
	margin-bottom: 10px;
	margin-left: 10px;
	padding-right: 0px;
	opacity: 1;
	color: #ffda28;
	font-size: 30px;
}

.slide-nav {
	left: auto;
	top: auto;
	right: 0%;
	bottom: 0%;
	display: block;
	width: 100%;
	height: 25px;
	margin-bottom: 10px;
	border-style: none;
	border-width: 1px;
	border-color: #ffda28;
	color: #ffda28;
	font-size: 11px;
}

.slide {
	background-image: url("/20191128115800/assets/images/5daf59504d7026e78c7888f0_Health_coaches_web_0FE756437EFC8.jpg");
	background-position: 50% 0%;
	background-size: cover;
	background-repeat: no-repeat;
}

.div-block-2 {
	position: absolute;
	left: 0%;
	top: auto;
	right: 0%;
	bottom: 0%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	margin-top: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-top: 10px solid #ffda28;
	border-radius: 0px;
	background-color: #1a3354;
}

.slider-caption {
	width: 100%;
}

.left-arrow {
	left: 0%;
	top: auto;
	right: auto;
	bottom: 0%;
	width: 54px;
	height: 42px;
	margin-right: 80px;
	margin-bottom: 10px;
	margin-left: 10px;
	border-radius: 18px;
	background-color: hsla(0, 0%, 100%, 0);
}

.right-arrow {
	position: absolute;
	left: auto;
	top: auto;
	right: 0%;
	bottom: 0%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 54px;
	height: 42px;
	margin-right: 10px;
	margin-bottom: 10px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 18px;
	background-color: rgba(255, 255, 255, 0);
}

.topnavigationholder {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	z-index: 999;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 60px;
	padding-right: 100px;
	padding-left: 100px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #ededed;
	background-image: linear-gradient(150deg, #004e59 29%, #4c9599 86%);
}

.two-part-mobile-nav-wrapper {
	display: none;
}

.nav-link-2 {
	width: auto;
	margin-right: auto;
	margin-left: auto;
	padding-top: 10px;
	padding-bottom: 5px;
	float: right;
	font-family: Lato, sans-serif;
	color: #004e59;
	font-size: 16px;
	font-weight: 400;
	text-align: center;
}

.nav-link-2:hover {
	border-bottom: 5px none #000;
	color: #1a3354;
}

.nav-link-2.mobileonly {
	display: none;
}

.navlinkunderline {
	position: static;
	left: 0%;
	top: auto;
	right: 0%;
	bottom: 0%;
	width: 0px;
	height: 4px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-radius: 2px;
	background-color: #ffda28;
}

.navlinkunderline:hover {
	width: 100%;
}

.navbar-2 {
	position: -webkit-sticky;
	position: sticky;
	top: 0px;
	z-index: 999;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 80px;
	max-width: none;
	margin-top: 59px;
	padding-right: 60px;
	padding-left: 60px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
	box-shadow: 0 12px 20px -17px #000;
}

.brand {
	width: 250px;
	max-width: 250px;
	margin-top: 10px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.nav-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 70%;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.main-nav-link {
	position: relative;
	display: block;
	padding: 0.5rem 1.25rem;
	-webkit-transition: all 150ms ease;
	transition: all 150ms ease;
	color: #000;
	font-size: 0.9rem;
	line-height: 1.2rem;
	font-weight: 500;
	text-decoration: none;
	cursor: pointer;
}

.main-nav-link.w--current {
	background-color: rgba(3, 52, 102, 0.6);
	color: #f9b604;
}

.main-nav-link.mobile-duplicate {
	display: none;
}

.header-search-container {
	position: absolute;
	left: auto;
	top: 100%;
	right: 0px;
	bottom: auto;
	z-index: 999;
	display: none;
	overflow: hidden;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
}

.navlinkholder {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	margin-right: 0px;
	margin-left: 10px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	background-color: transparent;
	position: relative;
	min-width: 0%;
}

.dropdownmegamenu {
	position: absolute;
	left: 0%;
	top: 100px;
	right: 0%;
	bottom: auto;
	z-index: 900;
	display: block;
	width: 500px;
	height: auto;
	padding-top: 10px;
	padding-right: 60px;
	padding-bottom: 10px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-align-content: center;
	-ms-flex-line-pack: center;
	align-content: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-top: 1px none #000;
	border-bottom: 1px none #ebebee;
	background-color: transparent;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#002f55), to(rgba(0, 47, 85, 0.9)));
	background-image: linear-gradient(180deg, #002f55, rgba(0, 47, 85, 0.9));
	opacity: 1;
}

.content-boxes {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: auto;
	max-width: none;
	margin-right: 0px;
	margin-bottom: 0px;
	padding: 40px 0%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-align-content: center;
	-ms-flex-line-pack: center;
	align-content: center;
	border: 0px none #000;
	background-color: #ededed;
	background-image: url("/20191128115800/assets/images/5daf59504d702642847888fb_AdobeStock_249422618.jpeg");
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: repeat;
	background-attachment: scroll;
}

.div-block-5 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.header-search-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: auto;
	height: 33px;
	margin-top: 0px;
	margin-bottom: 0px;
	margin-left: 10px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 100px;
}

.form-inside-wrapper,
.header-search-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	margin-top: 5px;
	margin-bottom: 5px;
	border-radius: 0px;
	background-color: #fff;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
}

.form-field {
	position: relative;
	width: auto;
	margin-bottom: 0.75em;
	padding: 4px 10px;
	font-size: 0.875em;
}

.form-field.header-search-field {
	width: 170px;
	height: 38px;
	margin-bottom: 0em;
	border-style: none;
	border-width: 0px;
	border-color: rgba(0, 0, 0, 0.15);
	border-radius: 0px;
	background-color: #fff;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	font-family: Lato, sans-serif;
	color: #373a3d;
	font-size: 0.75em;
	text-transform: uppercase;
}

.form-field.header-search-field:focus {
	width: 215px;
	border: 0px none transparent;
	background-color: #fff;
}

.button {
	display: inline-block;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	padding: 0.75em 1.5em 0.5em;
	background-color: #004e59;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #fff;
	line-height: 1.375em;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.05em;
	text-decoration: none;
	text-transform: uppercase;
}

.button:hover {
	background-color: #373a3d;
	color: #fff;
}

.button.header-search-button {
	width: 2.5rem;
	margin-top: 0em;
	margin-bottom: 0em;
	padding-top: 0em;
	padding-bottom: 0em;
	border-radius: 0px;
	background-color: #fff;
	background-image: url("/20191128115800/assets/images/5daf59504d702682157888f1_teal%20search.svg");
	background-position: 50% 50%;
	background-size: 1em 1em;
	background-repeat: no-repeat;
	opacity: 1;
	color: transparent;
	font-size: 0.875rem;
	line-height: 1em;
	text-transform: uppercase;
}

.button.header-search-button:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.button-2 {
	height: auto;
	margin-right: 10px;
	margin-left: 10px;
	padding-right: 20px;
	padding-left: 20px;
	border-radius: 20px;
	background-color: #fff;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-family: Lato, sans-serif;
	color: #1a3354;
	font-size: 12px;
	line-height: 15px;
	font-weight: 700;
}

.button-2:hover {
	background-color: #1a3354;
	color: #fff;
	font-weight: 700;
}

.image-slider {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0px;
	margin-right: 0px;
	margin-left: 0px;
	padding-right: 0%;
	padding-left: 0%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1px none #000;
	background-color: #fff;
}

.slider-caption p {
	margin: 50px 140px 90px;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 20px;
	line-height: 25px;
	font-weight: 400;
}

.footer-heading {
	margin-top: 0px;
	margin-bottom: 0.5rem;
	-webkit-align-self: auto;
	-ms-flex-item-align: auto;
	-ms-grid-row-align: auto;
	align-self: auto;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 20px;
	line-height: 1.7rem;
	font-weight: 400;
	text-align: left;
	letter-spacing: -0.02rem;
	text-transform: uppercase;
}

.footer-right-col-wrap {
	width: auto;
	min-width: auto;
	margin-left: 0px;
	padding-top: 0rem;
	padding-bottom: 0rem;
}

.footer-section {
	display: block;
	max-width: 1270px;
	margin-right: auto;
	margin-left: auto;
}

.footer-section.footer-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 1300px;
	max-width: 1300px;
	margin-right: 10px;
	margin-left: 10px;
	padding: 2rem 0vw;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	background-color: transparent;
}

.footer-nav-list {
	display: block;
	margin-top: 0.25rem;
	margin-right: 0px;
	margin-left: 10px;
	color: #fff;
	font-weight: 300;
	text-decoration: none;
}

.footer-nav-list:hover {
	color: #b3d236;
}

.submit-button {
	display: none;
}

.social-media-link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 30px;
	height: 30px;
	margin-right: 0.4rem;
	margin-left: 0.4rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-radius: 0%;
	background-color: #467ba9;
	-webkit-transition: all 300ms cubic-bezier(0.842, 0.007, 0.772, 1.001);
	transition: all 300ms cubic-bezier(0.842, 0.007, 0.772, 1.001);
	text-align: center;
}

.social-media-link-block:hover {
	border-radius: 1px;
	background-color: #b3d236;
}

.social-media-link-block.first-social-link {
	margin-right: 0.5rem;
	margin-left: 0rem;
	background-color: hsla(0, 0%, 100%, 0.21);
}

.social-media-link-block.first-social-link:hover {
	background-color: hsla(0, 0%, 100%, 0.49);
	opacity: 1;
}

.footer-nav-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1500px;
	padding-top: 0rem;
	padding-bottom: 0rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.footer-address {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	min-width: auto;
	padding-right: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.footer-nav-list-link {
	display: block;
	width: auto;
	margin-top: 0.25rem;
	padding-left: 14px;
	background-image: url("/20191128115800/assets/images/5daf59504d7026140e7888e2_chevron-right_white.svg");
	background-position: 0px 50%;
	background-size: 8px 8px;
	background-repeat: no-repeat;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 16px;
	font-weight: 300;
	text-decoration: none;
}

.footer-nav-list-link:hover {
	-webkit-transform: translate(5px, 0px);
	-ms-transform: translate(5px, 0px);
	transform: translate(5px, 0px);
	color: #ffda28;
}

.footer-nav-item {
	margin-top: 0rem;
	margin-bottom: 0.25rem;
	font-weight: 300;
}

.connect-with-us {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 20%;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.footer-paragraph {
	margin-bottom: 0px;
	padding-left: 0rem;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 16px;
	font-weight: 300;
}

.footer-paragraph.phone-paragraph {
	margin-top: 20px;
	padding-left: 0rem;
}

.goog-te-combo {
	min-height: 34px;
	min-width: 220px;
	margin-top: 2px;
	padding: 8px 20px 8px 8px;
	font-size: 0.9rem;
	line-height: 1.5rem;
}

.form-block {
	display: block;
}

.google-translate {
	clear: both;
}

.social-media-wrapper {
	position: relative;
	z-index: 1000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 2.5rem;
	margin-top: 0rem;
	margin-bottom: 1.2rem;
	float: left;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.link {
	color: #fff;
	text-decoration: none;
}

.google-translate-outer-wrap {
	display: none;
}

footer .footer {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0%;
	padding-left: 0%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-top: 10px solid #ffda28;
	background-color: rgba(26, 51, 84, 0.94);
}

.signoff-left {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-top: 1rem;
	margin-right: 0px;
	float: left;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-align-self: auto;
	-ms-flex-item-align: auto;
	align-self: auto;
	color: #fff;
	text-align: center;
}

.container-3 {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
}

.container-3.signoff-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1300px;
	margin-right: 10px;
	margin-left: 10px;
	padding-bottom: 1rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
}

.signoff-links-wrap {
	margin-bottom: 0.5rem;
	float: left;
	font-size: 0.8rem;
	line-height: 1.2rem;
}

.copyright-paragraph {
	display: inline-block;
	margin-top: 0rem;
	margin-right: 0.25rem;
	margin-bottom: 0.25rem;
	float: left;
	font-family: Lato, sans-serif;
	font-size: 0.9rem;
	line-height: 1.2rem;
	font-weight: 300;
}

.signoff-link {
	margin-right: 0.75rem;
	padding-bottom: 2px;
	box-shadow: inset 0 -1px 0 0 hsla(0, 0%, 100%, 0.4);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 13px;
	font-weight: 300;
	text-decoration: none;
}

.signoff-link:hover {
	box-shadow: inset 0 -1px 0 0 transparent;
	color: #ffda28;
	text-decoration: none;
}

.signoff-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	margin-right: auto;
	margin-left: auto;
	padding-right: 8vw;
	padding-left: 8vw;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px none hsla(0, 0%, 100%, 0.2);
	background-color: #1a3354;
}

.copyright-wrap {
	float: left;
}

.copyright {
	height: auto;
}

.div-block-6 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.footer-grip-link-2 {
	width: 133px;
	height: 29px;
	min-width: 0px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 33px;
	background-image: url("/20191128115800/assets/images/5daf59504d702668407888e9_globalreach_dark.svg");
	background-position: 50% 50%;
	background-size: 130px 29px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: none;
	cursor: pointer;
}

.footer-grip-link-2.white {
	margin-bottom: 0rem;
	background-image: url("/20191128115800/assets/images/5daf59504d70262a2a7888ec_globalreach_white.svg");
	background-size: 150px 29px;
}

.grip-logo-text-box-2 {
	color: #000;
	font-size: 0.5rem;
	line-height: 0.5rem;
	font-weight: 700;
	letter-spacing: 0.01rem;
	text-decoration: none;
	text-transform: uppercase;
}

.grip-logo-text-box-2.webdevby-white {
	color: #fff;
}

.heading-2 {
	margin-bottom: 10px;
	margin-top: 40px;
	margin-right: 140px;
	margin-left: 140px;
	color: #fff;
	font-size: 1.875rem;
	font-weight: 700;
}

.button-3 {
	position: absolute;
	left: 0%;
	top: auto;
	right: auto;
	bottom: 0%;
	margin: 10px 140px 40px;
	border: 2px solid #fff;
	border-radius: 20px;
	background-color: transparent;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	font-size: 13px;
	line-height: 13px;
	letter-spacing: 1px;
}

.button-3:hover {
	background-color: hsla(0, 0%, 100%, 0.2);
}

.about-us {
	display: none;
	overflow: visible;
	height: auto;
	padding: 140px 15%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 5px solid #fff;
	background-color: transparent;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.33)), to(rgba(0, 0, 0, 0.33))), url("/20191128115800/assets/images/5daf59504d70262e687888f3_hospital-image.jpeg");
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.33), rgba(0, 0, 0, 0.33)), url("/20191128115800/assets/images/5daf59504d70262e687888f3_hospital-image.jpeg");
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-attachment: scroll, fixed;
}

.paragraph-3 {
	width: auto;
	margin-bottom: 0px;
	border-bottom-right-radius: 100px;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 20px;
	line-height: 30px;
	font-weight: 400;
	text-align: center;
}

.white-heading {
	width: 90%;
	margin-top: 0px;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 30px;
	line-height: 40px;
	font-weight: 900;
	text-align: center;
}

.dropdown-toggle {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 20px;
	padding-right: 20px;
	padding-bottom: 20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.dropdown-toggle.w--open {
	padding-top: 20px;
	padding-bottom: 20px;
}

.dropdown-list {
	top: auto;
	margin-top: 0px;
}

.dropdown-list.w--open {
	width: 229px;
	margin-top: -2px;
	padding-right: 0px;
	padding-left: 0px;
	background-color: #1a3354;
	font-family: Lato, sans-serif;
	color: #fff;
}

.icon-3 {
	display: none;
	margin-top: 14px;
}

.nav-link-2-dropdown {
	width: auto;
	margin-right: auto;
	margin-left: auto;
	padding: 10px 10px 5px;
	float: right;
	font-family: Lato, sans-serif;
	color: #004e59;
	font-size: 16px;
	font-weight: 400;
	text-align: center;
}

.nav-link-2-dropdown:hover {
	border-bottom: 5px none #000;
	color: #1a3354;
}

.dropdown-link {
	width: 100%;
	margin-right: 0px;
	margin-left: 0px;
	padding-top: 5px;
	padding-right: 0px;
	padding-bottom: 5px;
	background-image: url("/20191128115800/assets/images/5daf59504d702615007888fa_white%20Background.png");
	background-position: -231px 0px;
	background-size: cover;
	background-repeat: no-repeat;
	-webkit-transition: all 600ms ease;
	transition: all 600ms ease;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 13px;
	font-weight: 700;
	padding: 0;
	background-color: #1a3354;
	white-space: normal;
}

.dropdown-link:hover {
	width: auto;
	background-position: 0px 0px;
}

.about-us-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	min-height: 200px;
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 0px;
	padding: 40px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 20px;
	background-color: rgba(0, 78, 89, 0.84);
}

.mobilesearchicon {
	display: none;
}

.mobilesearchblock-2 {
	display: none;
}

.slide-2 {
	border: 1px none #000;
	background-image: url("/20191128115800/assets/images/5daf59504d7026f2c6788901_ER_docs_for_home_page_4915713E922E1.jpg");
	background-position: 50% 0%;
	background-size: cover;
	background-repeat: no-repeat;
}

.body {
	max-width: none;
}

.image-13 {
	width: 50px;
	height: 50px;
	opacity: 0.67;
}

.heading-12 {
	margin-top: 5px;
	margin-bottom: 0px;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 12px;
	line-height: 20px;
	font-weight: 300;
	text-decoration: none;
	text-transform: capitalize;
}

.link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	margin-top: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-decoration: none;
}

.top-page-anchor {
	height: 65px;
	margin-bottom: -65px;
}

.div-block-22 {
	width: 25px;
	height: 25px;
	margin-top: -25px;
	margin-left: 42px;
	background-image: url("/20191128115800/assets/images/5daf59504d7026d2f9788904_navy%20drop%20down%20triangle.svg");
	background-position: 50% 100%;
	background-size: contain;
	background-repeat: no-repeat;
}

.footer-link {
	width: auto;
	height: 25px;
	margin-left: 0em;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 16px;
	font-weight: 700;
	text-decoration: none;
}

.footer-link:hover {
	border-bottom: 3px solid #ffda28;
	color: #fff;
}

.content-box-heading {
	width: 100%;
	margin-top: 0px;
	margin-bottom: 0px;
	padding-top: 20px;
	padding-bottom: 10px;
	background-color: rgba(26, 51, 84, 0.55);
	color: #ededed;
	text-align: center;
}

.heading-15 {
	width: 100%;
	padding-top: 0px;
	padding-bottom: 0px;
	border-style: none;
	border-width: 1px 1px 8px;
	border-color: #000;
	background-color: transparent;
	color: #1a3354;
	font-size: 25px;
	text-align: left;
}

.div-block-26 {
	width: 100%;
}

.foundation-image {
	width: 100%;
	height: 100%;
	max-width: none;
	border-bottom: 8px none #ffda28;
	object-fit: cover;
	object-position: top;
}

.quick-links-list {
	display: block;
	width: 100%;
	margin-top: 0.25rem;
	margin-right: 0px;
	padding-left: 0px;
	color: #fff;
	font-weight: 300;
	text-decoration: none;
}

.quick-links-list:hover {
	color: #b3d236;
}

.div-block-32 a,
.quick-links {
	display: block;
	width: auto;
	padding-left: 0px;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 15px;
	font-weight: 400;
	text-decoration: none;
}

.div-block-32 p {
	margin-top: 10px;
	margin-bottom: 10px;
}

.div-block-32 a:hover,
.quick-links:hover {
	-webkit-transform: translate(5px, 0px);
	-ms-transform: translate(5px, 0px);
	transform: translate(5px, 0px);
	color: #ffda28;
}

.news-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25%;
	height: auto;
	max-height: none;
	max-width: none;
	min-width: 250px;
	margin: 10px 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	position: relative;
	padding-bottom: 40px;
}

.content-box-img-container {
	overflow: hidden;
	width: 100%;
	height: 250px;
	border-bottom: 8px solid #ffda28;
}

.button-4 {
	position: static;
	padding-top: 9px;
	padding-bottom: 9px;
	border-style: solid;
	border-width: 1px;
	border-color: #1b6c77;
	border-radius: 20px;
	background-color: #1b6c77;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 13px;
	font-weight: 700;
	letter-spacing: 1px;
}

.button-4:hover {
	background-color: transparent;
	color: #1b6c77;
}

.link-5 {
	margin-bottom: 5px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Lato, sans-serif;
	color: #4c9599;
	font-size: 15px;
}

.link-5:hover {
	color: #004e59;
}

.div-block-30 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-right: 0px;
	padding-right: 10px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	position: absolute;
	bottom: 0;
}

.news-date {
	width: 100%;
	padding-bottom: 5px;
	border-bottom: 1px solid #ffda28;
	color: #1a3354;
	font-weight: 700;
}

.newsevents-img {
	width: 100%;
	height: 100%;
	max-width: none;
	border-bottom: 8px none #ffda28;
	object-fit: cover;
}

.quick-links-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 17%;
	height: auto;
	max-height: none;
	max-width: none;
	min-width: 190px;
	margin-top: 10px;
	margin-bottom: 10px;
	margin-left: 0px;
	padding-top: 10px;
	padding-right: 20px;
	padding-left: 20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-radius: 20px;
	background-color: #1b6c77;
	color: transparent;
}

.services-img {
	width: 100%;
	height: 100%;
	max-width: none;
	border-bottom: 8px none #ffda28;
	object-fit: cover;
}

.quick-link-heading {
	width: 100%;
	margin-top: 20px;
	padding-top: 0px;
	padding-bottom: 0px;
	border-style: none;
	border-width: 1px 1px 8px;
	border-color: #000;
	background-color: transparent;
	color: #fff;
	font-size: 25px;
	line-height: 30px;
	text-align: left;
}

.div-block-32 {
	width: 100%;
}

.mask {
	border: 1px none #000;
}

.quick-links-footer {
	width: auto;
	min-width: auto;
	margin-left: 10px;
	padding-right: 0rem;
}

.div-block-33 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1300px;
	margin-right: 10px;
	margin-left: 10px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-content: stretch;
	-ms-flex-line-pack: stretch;
	align-content: stretch;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
}

.div-block-34 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.footer-heading-copy {
	margin-top: 0px;
	margin-bottom: 0.5rem;
	padding-left: 10px;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 20px;
	line-height: 1.7rem;
	font-weight: 400;
	text-align: left;
	letter-spacing: -0.02rem;
	text-transform: uppercase;
}

.connect-with-us-heading {
	margin-top: 0px;
	margin-bottom: 0.5rem;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 20px;
	line-height: 1.7rem;
	font-weight: 400;
	text-align: right;
	letter-spacing: -0.02rem;
	text-transform: uppercase;
}

.contact-us {
	width: auto;
	height: 24px;
	margin-left: 0em;
	-webkit-box-ordinal-group: 1;
	-webkit-order: 0;
	-ms-flex-order: 0;
	order: 0;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 16px;
	font-weight: 700;
	text-decoration: none;
}

.contact-us:hover {
	border-bottom: 3px solid #ffda28;
	color: #fff;
}

.div-block-35 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 105%;
	height: 100%;
	max-width: 1300px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.text-link {
	border-bottom: 1px none #ffda28;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #004e59;
	line-height: 1rem;
	text-decoration: underline;
}

.text-link:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
	color: #1a3354;
}

.text-link.w--current {
	color: #28313b;
	font-weight: 700;
}

.mobile-menu-styles-block {
	position: relative;
	overflow: hidden;
	background-color: #21272e;
}

.main-content-wrapper {
	overflow: hidden;
	padding-top: 0.6rem;
}

.main-content-wrapper.no-sidebars {
	font-size: 1.125rem;
}

.intro_paragraph,
.intro-paragraph,
.intro-text-style {
	margin-bottom: 0.75rem;
	color: #313131;
	font-size: 1.35rem;
	line-height: 1.95rem;
}

.nav-link-3 {
	display: inline-block;
	margin-right: 1px;
	margin-left: 1px;
	padding: 0.5rem;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	background-image: url("/20191128115800/assets/images/5dc584151d55e47b497b766e_tab-01.svg");
	background-position: 50% 125%;
	background-size: 50px 4px;
	background-repeat: no-repeat;
	box-shadow: none;
	color: #313131;
	line-height: 1.375rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.02em;
	text-transform: uppercase;
}

.nav-link-3:hover {
	color: rgba(9, 49, 76, 0.5);
}

.nav-link-3.w--current {
	background-position: 50% 104%;
	color: #6b6b6b;
}

.nav-link-3.w--current:hover {
	color: #677179;
}

.header-link {
	border-bottom: 1px solid #797676;
	color: #721926;
}

.header-link:hover {
	border-bottom-color: transparent;
	color: #797676;
}

.header-link.h6-link {
	border-bottom-width: 3px;
	border-bottom-color: #ffda28;
	color: #1a3354;
	text-decoration: none;
}

.header-link.h6-link:hover {
	color: #004e59;
}

.header-link.h5-link {
	border-bottom-width: 3px;
	border-bottom-color: #ffda28;
	color: #1a3354;
	text-decoration: none;
}

.header-link.h5-link:hover {
	color: #004e59;
}

.header-link.h1-link {
	border-bottom-width: 4px;
	border-bottom-color: #ffda28;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #1a3354;
	text-decoration: none;
}

.header-link.h1-link:hover {
	color: #004e59;
}

.header-link.h3-link {
	border-bottom-width: 3px;
	border-bottom-color: #ffda28;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #1a3354;
	text-decoration: none;
}

.header-link.h3-link:hover {
	color: #004e59;
}

.header-link.h4-link {
	border-bottom-width: 3px;
	border-bottom-color: #ffda28;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #1a3354;
	text-decoration: none;
}

.header-link.h4-link:hover {
	color: #004e59;
}

.header-link.h2-link {
	border-bottom-width: 4px;
	border-bottom-color: #ffda28;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #1a3354;
	text-decoration: none;
}

.header-link.h2-link:hover {
	color: #004e59;
}

.table-style-embed {
	color: #1a3354;
}

.two-part-button-wrap {
	position: relative;
	display: block;
	max-width: 768px;
	margin-right: auto;
	margin-left: auto;
}

.form-success {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	border: 2px solid #48a700;
	background-color: #caf3aa;
	color: #000;
	font-size: 1.125rem;
}

.form-wrap {
	margin-bottom: 0px;
	font-size: 1rem;
	font-weight: 400;
}

.inside-page-content-wrap {
	max-width: 1300px;
	width: 100%;
	font-family: Lato, sans-serif;
}

.divider {
	height: 1px;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	clear: both;
	background-color: #c9c6c4;
}

.button-5 {
	display: inline-block;
	min-width: 100px;
	margin-top: 1rem;
	margin-right: 1rem;
	margin-bottom: 0.5rem;
	padding: 9px 15px;
	border-style: solid;
	border-width: 1px;
	border-color: #004e59;
	border-radius: 20px;
	background-color: #004e59;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #fff;
	font-size: 12px;
	line-height: 20px;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
}

.button-5:hover {
	border-style: solid;
	border-width: 1px;
	border-color: #004e59;
	background-color: transparent;
	color: #004e59;
}

.button-5.secondary {
	border-style: solid;
	border-width: 1px;
	border-color: #1a3354;
	background-color: #fff;
	color: #1a3354;
}

.button-5.secondary:hover {
	background-color: #1a3354;
	color: #fff;
}

.button-5.tertiary {
	min-width: 0px;
	padding: 0.25rem 0.75rem;
	border-style: solid;
	border-width: 1px;
	border-color: #1a3354;
	background-color: #fff;
	color: #1a3354;
	font-size: 12px;
}

.button-5.tertiary:hover {
	background-color: rgba(0, 0, 0, 0.05);
}

.form-error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	border: 2px solid #721926;
	background-color: #ffccd0;
	color: #000;
	font-size: 1.125rem;
	font-weight: 400;
}

.styles-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: none;
	padding-top: 0px;
	padding-bottom: 60px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.breadcrumb-list {
	margin: 1.7rem 0.0625rem 1.5em;
	padding-top: 0.875em;
	padding-bottom: 0.875em;
	padding-left: 0px;
	font-family: Oswald, sans-serif;
	color: rgba(122, 146, 139, 0.5);
	font-size: 1em;
	line-height: 1em;
	font-weight: 400;
	text-transform: uppercase;
}

.breadcrumb-list-item {
	margin-top: 0.25rem;
	margin-right: 0.75em;
	margin-bottom: 0.25rem;
	float: left;
	color: #1a3354;
	font-size: 0.625em;
	line-height: 1.25em;
}

.link-6,
.breadcrumbs-div .breadcrumb-list .breadcrumb-list-item .link-6 {
	display: inline;
	color: #1a3354;
	text-decoration: underline;
	border: 0;
	line-height: 1;
}

.link-6:hover,
.breadcrumbs-div .breadcrumb-list .breadcrumb-list-item .link-6:hover {
	color: #4c9599;
}

.link-6.w--current,
.breadcrumbs-div .breadcrumb-list .breadcrumb-list-item .link-6.w--current {
	color: #28313b;
	font-weight: 700;
}

.link-6.breadcrumb-list-link,
.breadcrumbs-div .breadcrumb-list .breadcrumb-list-item .link-6.breadcrumb-list-link {
	color: #1a3354;
	letter-spacing: 0.04em;
	text-decoration: underline;
}

.link-6.breadcrumb-list-link:hover,
.breadcrumbs-div .breadcrumb-list .breadcrumb-list-item .link-6.breadcrumb-list-link:hover {
	color: #004e59;
	text-decoration: none;
}

.link-6.breadcrumb-list-link.current,
.breadcrumbs-div .breadcrumb-list .breadcrumb-list-item .link-6.breadcrumb-list-link.current {
	color: #407f82;
	text-decoration: none;
}

.link-6.h2-link,
.breadcrumbs-div .breadcrumb-list .breadcrumb-list-item .link-6.h2-link {
	color: #2a779b;
}

.link-6.h2-link:hover,
.breadcrumbs-div .breadcrumb-list .breadcrumb-list-item .link-6.h2-link:hover {
	color: #929fa5;
	text-decoration: none;
}

.link-6.breadcrumb-list-link,
.breadcrumbs-div .breadcrumb-list .breadcrumb-list-item .link-6.breadcrumb-list-link {
	color: #2a779b;
	letter-spacing: 0.04em;
	text-decoration: underline;
}

.link-6.breadcrumb-list-link:hover,
.breadcrumbs-div .breadcrumb-list .breadcrumb-list-item .link-6.breadcrumb-list-link:hover {
	color: #2a779b;
	text-decoration: none;
}

.link-6.breadcrumb-list-link.current,
.breadcrumbs-div .breadcrumb-list .breadcrumb-list-item .link-6.breadcrumb-list-link.current {
	text-decoration: none;
}

.link-6.h3-link:hover,
.breadcrumbs-div .breadcrumb-list .breadcrumb-list-item .link-6.h3-link:hover {
	color: #9c9c9c;
	text-decoration: none;
}

.link-6.h5-link:hover,
.breadcrumbs-div .breadcrumb-list .breadcrumb-list-item .link-6.h5-link:hover {
	color: #929fa5;
}

.link-6.h4-link,
.breadcrumbs-div .breadcrumb-list .breadcrumb-list-item .link-6.h4-link {
	color: #2a779b;
}

.link-6.h4-link:hover,
.breadcrumbs-div .breadcrumb-list .breadcrumb-list-item .link-6.h4-link:hover {
	color: #929fa5;
}

.link-6.h6-link:hover,
.breadcrumbs-div .breadcrumb-list .breadcrumb-list-item .link-6.h6-link:hover {
	color: #929fa5;
}

.breadcrumb-divider {
	margin-left: 0.75em;
	float: right;
}

.list {
	width: 100%;
	max-width: 1300px;
	margin-top: 1.3rem;
	margin-bottom: 0em;
	-webkit-align-self: auto;
	-ms-flex-item-align: auto;
	-ms-grid-row-align: auto;
	align-self: auto;
	font-family: Lato, sans-serif;
	color: #1a3354;
	font-size: 1.2em;
	font-weight: 400;
}

.bread-crumb-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 40px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.heading-16 {
	margin-top: 10px;
	color: #1a3354;
	font-size: 2.3rem;
	line-height: 1.3;
}

.heading-17 {
	margin-top: 40px;
	color: #1a3354;
}

.heading-18 {
	margin-top: 40px;
	color: #1a3354;
}

.heading-19 {
	color: #1a3354;
}

.heading-20 {
	color: #1a3354;
}

.heading-21 {
	color: #1a3354;
}

.field-label {
	color: #1a3354;
}

.field-label-2 {
	color: #1a3354;
}

.heading-22 {
	color: #1a3354;
}

.block-quote {
	width: auto;
	margin-top: 20px;
	padding-top: 20px;
	padding-bottom: 20px;
	border-left-color: #ffda28;
	background-color: #1a3354;
	color: #fff;
	line-height: 25px;
}

.box-quote-link {
	border-bottom: 1px none #ffda28;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #ffda28;
	line-height: 1rem;
	text-decoration: underline;
}

.box-quote-link:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
	color: #a0a0a0;
}

.box-quote-link.w--current {
	color: #28313b;
	font-weight: 700;
}

.paragraph-4 {
	margin-bottom: 40px;
}

.container {
	max-width: 1160px;
	padding-right: 25px;
	padding-left: 25px;
}

.screen-message {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

.screen-message.success {
	background-color: rgba(17, 185, 30, 0.25);
}

.screen-message.error {
	background-color: rgba(253, 54, 54, 0.15);
}

.left-nav-list-link {
	display: block;
	padding: 0.75em 1em;
	font-family: Lato, sans-serif;
	color: #1a3354;
	font-size: 1.2em;
	line-height: 1.3125em;
	font-weight: 400;
	letter-spacing: 0.03em;
	text-decoration: none;
}

.left-nav-list-link:hover {
	color: #4c9599;
}

.left-nav-list-link.selected,
.left-nav-list-link.w--current {
	background-color: transparent;
	background-image: none;
	box-shadow: -4px 0 0 0 #004e59;
	color: #407f82;
}

.left-nav-list-link.selected:hover,
.left-nav-list-link.w--current:hover {
	color: #407f82;
}

.left-nav-nested-list-link {
	display: block;
	padding: 0.75em 2.25em 0.8125em 0em;
	color: #1a3354;
	font-size: 1em;
	line-height: 1.4em;
	text-decoration: none;
}

.left-nav-nested-list-link:hover {
	color: #407f82;
}

.left-nav-nested-list-link.selected,
.left-nav-nested-list-link.w--current {
	padding-right: 1.25em;
	padding-left: 1em;
	background-color: transparent;
	box-shadow: inset 4px 0 0 0 #004e59;
	color: #407f82;
	font-weight: 400;
	letter-spacing: 0.02em;
}

.left-nav-nested-list-link.selected:hover,
.left-nav-nested-list-link.w--current:hover {
	color: #407f82;
}

.heading-23 {
	color: #2a779b;
}

.left-nav-nested-list {
	margin-top: 0em;
	margin-bottom: 0em;
	padding: 0em 0em 0em 1em;
	border-top: 1px solid #e9ece3;
}

.left-nav-list-item {
	margin-bottom: 1px;
	box-shadow: 0 -1px 0 0 #e9ece3;
}

.w-col {
	padding-left: 0;
	padding-right: 0;
}

.inside-row {
	max-width: 1420px;
	padding-bottom: 40px;
	padding-left: 60px;
	padding-right: 60px;
}

.left-nav-list-content-wrap {
	display: block;
	height: auto;
	margin-top: -1.9rem;
	padding-top: 2rem;
	padding-bottom: 3em;
	border-right: 1px solid #e9ece3;
	font-family: Lato, sans-serif;
	text-decoration: none;
}

.button-6 {
	display: inline-block;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	padding: 1em 1.5em;
	background-color: #225e7c;
	-webkit-transition: background-color 200ms ease, color 200ms ease;
	transition: background-color 200ms ease, color 200ms ease;
	font-family: Oswald, sans-serif;
	color: #fff;
	font-size: 0.875em;
	line-height: 1.125em;
	font-weight: 400;
	text-align: center;
	letter-spacing: 0.07em;
	text-decoration: none;
	text-transform: uppercase;
}

.button-6:hover {
	background-color: #67686b;
	color: #fff;
}

.button-6.tertiary {
	padding: 0.7em 0.75em;
	background-color: #67686b;
	font-size: 0.75em;
}

.button-6.tertiary:hover {
	background-color: #53575a;
}

.button-6.secondary {
	background-color: #2a779b;
}

.button-6.secondary:hover {
	background-color: #67686b;
}

.inside-page-content-wrap-no-sidebars-2 {
	margin-right: 20%;
}

.left-nav-column {
	padding-top: 2em;
	padding-right: 20px;
}

.left-nav-list {
	margin-top: 0em;
	margin-bottom: 1.5em;
	padding-left: 0px;
	box-shadow: none;
}

.screen-message-text {
	color: #28292a;
	font-weight: 500;
}

.divider-2 {
	height: 1px;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	clear: both;
	background-color: #e9ece3;
}

.paragraph-2 {
	color: #28292a;
}

.intro-text-style-2 {
	color: #28292a;
	font-size: 1.1875em;
	line-height: 1.5em;
}

.left-nav-nested-list-item {
	margin-bottom: 1px;
	box-shadow: 0 -1px 0 0 #e9ece3;
}

.right-sidebar-list {
	padding-left: 0px;
}

.right-sidebar-section-content p {
	font-size: 0.875rem;
}

.right-sidebar-section-content > h1,
.right-sidebar-section-content > h2,
.right-sidebar-section-content > h3,
.right-sidebar-section-content > h4 {
	margin-top: 0.25em;
	margin-bottom: 0.75em;
	color: #1a3354;
	font-size: 1.375em;
}

.right-sidebar-section-wrap {
	position: relative;
	margin-bottom: 2.5em;
	margin-left: 1em;
	padding-top: 0.75em;
	padding-right: 5px;
	padding-left: 5px;
	border-top: 1px solid #e9ece3;
	font-size: 0.875rem;
	line-height: 1.5em;
}

.contentbox_item .contentbox_item_image img {
	max-width: none;
}

.right-sidebar-section-content a,
.link-7 {
	display: inline;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #004e59;
	text-decoration: underline;
	border: 0;
	line-height: 1;
}

.right-sidebar-section-content a:hover,
.link-7:hover {
	color: #4c9599;
}

.right-sidebar-section-content a.w--current,
.link-7.w--current {
	color: #28313b;
	font-weight: 700;
}

.right-sidebar-section-content a.h2-link,
.link-7.h2-link {
	color: #2a779b;
}

.right-sidebar-section-content a.h2-link:hover,
.link-7.h2-link:hover {
	color: #929fa5;
	text-decoration: none;
}

.right-sidebar-section-content a.breadcrumb-list-link,
.link-7.breadcrumb-list-link {
	color: #2a779b;
	letter-spacing: 0.04em;
	text-decoration: underline;
}

.right-sidebar-section-content a.breadcrumb-list-link:hover,
.link-7.breadcrumb-list-link:hover {
	color: #2a779b;
	text-decoration: none;
}

.right-sidebar-section-content a.breadcrumb-list-link.current,
.link-7.breadcrumb-list-link.current {
	text-decoration: none;
}

.right-sidebar-section-content a.h3-link:hover,
.link-7.h3-link:hover {
	color: #9c9c9c;
	text-decoration: none;
}

.right-sidebar-section-content a.h5-link:hover,
.link-7.h5-link:hover {
	color: #929fa5;
}

.right-sidebar-section-content a.h4-link,
.link-7.h4-link {
	color: #2a779b;
}

.right-sidebar-section-content a.h4-link:hover,
.link-7.h4-link:hover {
	color: #929fa5;
}

.right-sidebar-section-content a.h6-link:hover,
.link-7.h6-link:hover {
	color: #929fa5;
}

.breadcrumb-list-item-2 {
	margin-top: 0.25rem;
	margin-right: 0.75em;
	margin-bottom: 0.25rem;
	float: left;
	font-size: 0.625em;
	line-height: 1.25em;
}

.right-sidebar-list-item {
	margin-bottom: 0.75em;
	padding-left: 0px;
}

.right-sidebar-section-title {
	margin-top: 0.25em;
	margin-bottom: 0.75em;
	color: #1a3354;
	font-size: 1.375em;
}

.div-block-36 {
	padding-top: 2em;
}

.link-8 {
	display: inline;
	color: #2a779b;
	text-decoration: underline;
}

.link-8.w--current {
	color: #28313b;
	font-weight: 700;
}

.link-8.h2-link {
	color: #2a779b;
}

.link-8.h2-link:hover {
	color: #929fa5;
	text-decoration: none;
}

.link-8.breadcrumb-list-link {
	color: #2a779b;
	letter-spacing: 0.04em;
	text-decoration: underline;
}

.link-8.breadcrumb-list-link:hover {
	color: #2a779b;
	text-decoration: none;
}

.link-8.breadcrumb-list-link.current {
	text-decoration: none;
}

.link-8.h3-link:hover {
	color: #9c9c9c;
	text-decoration: none;
}

.link-8.h5-link:hover {
	color: #929fa5;
}

.link-8.h4-link {
	color: #2a779b;
}

.link-8.h4-link:hover {
	color: #929fa5;
}

.link-8.h6-link:hover {
	color: #929fa5;
}

.inside-row-2 {
	max-width: 1300px;
}

.right-sidebar-section-title-2 {
	margin-top: 0.25em;
	margin-bottom: 0.75em;
	color: #2a779b;
	font-size: 1.375em;
}

.inside-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: none;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	min-height: calc( 100vh - 484px );
	background-color: #fff;
}

.columns {
	max-width: 1300px;
}

.section-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.section-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.styles-content-copy {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: none;
	padding-top: 21px;
	padding-bottom: 60px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.paragraph-5 {
	margin-top: 10px;
	line-height: 27px;
}

.heading-24 {
	color: #1a3354;
	font-weight: 900;
}

.heading-25 {
	color: #1a3354;
	font-weight: 900;
}

.paragraph-6 {
	line-height: 27px;
}

/* Homepage content boxes */
#news_module.homepage_contentbox > a {
	display: none;
}

#news_module.homepage_contentbox .cms_list_item a {
	margin-bottom: 5px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Lato, sans-serif;
	color: #4c9599;
	font-size: 15px;
	line-height: 20px;
}

#news_module.homepage_contentbox .cms_list_item a:hover {
	color: #004e59;
}

#news_module.homepage_contentbox .cms_list_item .cms_metadata1.cms_title h3 {
	margin: 0;
	font-weight: 400;
	line-height: 20px;
}

#news_module.homepage_contentbox .cms_list_item .cms_metadata2.cms_date h3 {
	width: 100%;
	padding-bottom: 5px;
	border-bottom: 1px solid #ffda28;
	color: #1a3354;
	font-weight: 700;
	font-size: 14px;
	margin-top: 0;
}

#news_module.homepage_contentbox .cms_list_item .cms_description {
	display: none;
}
/* end of Homepage content boxes */

/* main navigation */
.main-nav-ul {
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	height: 100%;
}

.open .dropdown-list {
	height: auto;
	opacity: 1;
	display: block;
}

.child-ul {
	padding: 0;
	margin: 0;
}

.child-ul::before {
	content: " ";
	display: block;
	width: 100%;
	height: 25px;
	margin-top: -25px;
	background-image: url("/20191128115800/assets/images/5daf59504d7026d2f9788904_navy%20drop%20down%20triangle.svg");
	background-position: 42px 100%;
	background-size: contain;
	background-repeat: no-repeat;
}

.dropdown-list.w-dropdown-list {
	background: transparent;
	top: 100%;
	width: 229px;
	left: 0;
}

.dropdown-link a {
	color: #fff;
	text-decoration: none;
	width: 100%;
	display: block;
	padding: 5px 20px;
}

.open .nav-link-2.w-nav-link:after,
.nav-link-2.w-nav-link:after {
	content: " ";
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: auto;
	right: 0%;
	bottom: -10%;
	width: 0;
	height: 4px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-radius: 2px;
	background-color: #ffda28;
	transition: width 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

.open .nav-link-2.w-nav-link:after,
.nav-link-2.w-nav-link:hover:after {
	width: 90%;
}

.navlinkholder:last-of-type .dropdown-list.w-dropdown-list {
	right: 0;
	left: auto;
}

.navlinkholder:last-of-type .child-ul::before {
	background-position: calc(100% - 35px) 100%;
}

a.nav-link-2.active-item {
	color: #004e59;
}

a.nav-link-2.active-item:after {
	width: 90%;
}
/* end of main navigation */

.table-wrapper {
	overflow: auto;
}

.menu-table {
	table-layout: fixed;
	word-break: break-word;
}

.menu-table tr th {
	width: 160px !important;
}

@media (max-width: 39.9375em) {
	.menu-table tr th {
		width: 100% !important;
	}
}

/*mmenu*/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}
	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~ .mm-slideout {
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		transform: translate(0, 0);
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@media (max-width: 991px) {
	.mm-menu {
		background: #1a3354;
	}
	.navigation-menu {
		top: 0;
		margin-top: 0;
		width: 80%;
	}
	.mobile-navigation-menu,
	.desktop-navigation-menu {
		display: none !important;
	}
	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
	.mm-wrapper_opening .mm-menu_position-right.mm-menu_opened~ .mm-slideout {
		-webkit-transform: translate(-80%, 0);
		-ms-transform: translate(-80%, 0);
		transform: translate(-80%, 0);
		-webkit-transform: translate3d(-80%, 0, 0);
		transform: translate3d(-80%, 0, 0);
	}
	.mm-menu_offcanvas {
		max-width: none;
	}
	.mm-panel {
		padding: 0;
	}
	.mm-panels > .mm-panel > .mm-listview {
		margin: -20px 0 20px;
	}
	.mm-panels > .mm-panel + .mm-panel > .mm-listview {
		margin-top: 41px;
	}
	.mm-menu li {
		display: block !important;
	}
	.mm-menu a,
	.mm-menu a:active,
	.mm-menu a:link,
	.mm-menu a:visited {
		color: #fff;
		transition: 300ms all ease;
		padding: 1.25em 1.375rem;
		float: none;
		box-shadow: none;
		border-bottom: 1px solid #53667f;
		text-transform: uppercase;
		font-family: Lato, sans-serif;
	}
	.mm-menu .active-item > a:not(.mm-btn_next),
	.mm-menu .active-item > span,
	.mm-menu .mm-listitem_selected > a:not(.mm-btn_next),
	.mm-menu .mm-listitem_selected > span {
		background-color: #485c76;
		cursor: pointer;
	}
	.mm-menu a.w--current {
		background-color: #485c76;
		color: #fff;
		cursor: pointer;
	}
	.mm-menu a:hover {
		background-color: #485c76;
		color: #fff;
		cursor: pointer;
	}
	.mm-listitem:after {
		border-bottom-width: 0px;
	}
	.mm-menu .mm-btn_prev:hover,
	.mm-menu .mm-navbar__title:hover,
	.mm-menu .mm-btn_next:hover {
		color: #fff;
		background-color: transparent;
		cursor: pointer;
	}
	.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) {
		width: 20%;
	}
	.mm-listitem .mm-btn_next:before {
		border-left-width: 1px;
		border-left-color: #53667f;
	}
	.mm-menu .mm-btn:after,
	.mm-menu .mm-btn:before,
	.mm-menu .mm-listview .mm-btn_next:after {
		border-color: #fff;
		-webkit-transition: border-color 300ms;
		transition: border-color 300ms;
	}
	.mm-menu .mm-btn:hover:after,
	.mm-menu .mm-btn:hover:before,
	.mm-menu .mm-listview .mm-btn_next:hover:after {
		border-color: #fff;
	}
	.mm-btn_next:after,
	.mm-btn_prev:before {
		width: 9px;
		height: 9px;
	}
	.mm-btn_next:after {
		right: 44%;
	}
	.mm-btn_prev:before {
		margin-left: 0;
	}
	.mm-menu .mm-listview .mm-btn_next:hover {
		background-color: #485c76;
		color: #282f55;
		cursor: pointer;
	}
	.mm-menu .mm-listview .mm-btn_next + a {
		width: 80%;
		margin-right: 20%;
	}
	.mm-navbar {
		border-bottom: 1px solid #53667f;
		padding: 1px 10px 0 40px;
		height: 61px;
		margin-right: 0;
		transition: 300ms all ease;
	}
	.mm-menu .mm-navbar a,
	.mm-menu .mm-navbar > * {
		transition: color 300ms;
		height: 61px;
		border-bottom: 0 none !important;
	}
	.mm-menu a.mm-navbar__title {
		font-size: 1rem;
		line-height: 1.25rem;
		font-weight: 400;
		text-align: left;
		padding-left: 8px !important;
		text-transform: capitalize;
		border-bottom: 0 none;
	}
	.mm-navbar:hover {
		cursor: pointer;
		background-color: #485c76;
	}
}
/* end of mmenu */

/* media queries */
@media (max-width: 991px) {
	.slider {
		height: 500px;
	}
	.div-block-2 {
		height: auto;
		margin-top: -389px;
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
		background-color: #1a3354;
	}
	.topnavigationholder {
		padding-right: 5px;
		padding-left: 5px;
	}
	.two-part-mobile-nav-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
	}
	.menu-button {
		position: absolute;
		left: auto;
		top: 0%;
		right: 2%;
		bottom: 0%;
		padding-top: 32px;
	}
	.menu-button.current {
		background-color: transparent;
		color: #e22fe2;
	}
	.menu-button.current.w--open {
		background-color: transparent;
	}
	.menu-button.current.open {
		padding-top: 18px;
	}
	.nav-link-2 {
		display: block;
		padding-bottom: 10px;
		padding-left: 30px;
		float: none;
		color: #fff;
		text-align: left;
	}
	.nav-link-2.mobileonly {
		display: block;
		padding-bottom: 10px;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
		background-image: url("/20191128115800/assets/images/5daf59504d702615007888fa_white%20Background.svg");
		background-position: -384px 0px;
		background-size: cover;
		background-repeat: no-repeat;
		-webkit-transition: all 500ms ease;
		transition: all 500ms ease;
		font-weight: 700;
	}
	.nav-link-2.mobileonly:hover {
		background-color: hsla(0, 0%, 100%, 0.1);
		background-position: 0px 0px;
		color: #fff;
	}
	.navlinkunderline {
		display: none;
	}
	.navbar-2 {
		display: block;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.brand {
		position: absolute;
		left: 5%;
		top: 0%;
		right: auto;
		bottom: 0%;
		width: 240px;
		height: 75px;
		margin-top: 0px;
	}
	.icon-2 {
		color: #004e59;
		font-size: 40px;
	}
	.nav-menu {
		position: absolute;
		left: auto;
		top: 0%;
		right: 0%;
		bottom: 0%;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 50%;
		margin-top: 80px;
		padding-top: 0px;
		padding-bottom: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
		background-color: transparent;
		background-image: -webkit-gradient(linear, left top, left bottom, from(#1a3354), to(#1a3354));
		background-image: linear-gradient(180deg, #1a3354, #1a3354);
		font-family: Lato, sans-serif;
	}
	.main-nav-link {
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		text-align: left;
	}
	.main-nav-link.mobile-duplicate {
		display: block;
		width: 88%;
		padding: 0.75rem 1.25rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom-style: none;
		background-color: transparent;
	}
	.main-nav-link.mobile-duplicate:hover {
		background-color: rgba(69, 120, 188, 0.35);
		background-image: none;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}
	.main-nav-link.mobile-duplicate.w--current {
		box-shadow: inset 6px 0 0 0 #f9b604;
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		color: #f9b604;
	}
	.main-nav-link.mobile-duplicate.nav-link {
		width: 100%;
		padding-left: 30px;
		background-image: url("/20191128115800/assets/images/5daf59504d702615007888fa_white%20Background.svg");
		background-position: -384px 0px;
		background-size: cover;
		background-repeat: no-repeat;
		-webkit-transition-duration: 500ms;
		transition-duration: 500ms;
		font-family: Lato, sans-serif;
		color: #fff;
		font-size: 16px;
		line-height: 20px;
		font-weight: 700;
	}
	.main-nav-link.mobile-duplicate.nav-link:hover {
		background-color: transparent;
		background-position: 0px 0px;
	}
	.header-search-container {
		right: 0px;
		display: none;
	}
	.navlinkholder {
		display: none;
		margin-right: 0px;
		margin-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
	}
	.image-3 {
		margin-top: 8px;
	}
	.dropdownmegamenu {
		display: none;
	}
	.content-boxes {
		margin-right: 0px;
		margin-bottom: 0px;
		padding: 20px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		-webkit-align-content: center;
		-ms-flex-line-pack: center;
		align-content: center;
	}
	.header-search-wrap {
		display: none;
		margin-left: 0rem;
		padding: 0rem 0rem 0px;
		background-color: transparent;
		box-shadow: none;
		-webkit-transition: all 350ms ease;
		transition: all 350ms ease;
	}
	.form-inside-wrapper,
	.header-search-form {
		position: relative;
		padding: 0rem;
		-webkit-transition: all 350ms ease;
		transition: all 350ms ease;
	}
	.form-field.header-search-field {
		width: 70%;
		height: 2rem;
		padding-left: 1em;
		-webkit-box-flex: 100%;
		-webkit-flex: 100%;
		-ms-flex: 100%;
		flex: 100%;
		font-size: 0.8125rem;
	}
	.form-field.header-search-field:focus {
		width: 280px;
		min-width: 280px;
	}
	.button.header-search-button {
		position: absolute;
		right: 0rem;
		width: 3rem;
		height: 2rem;
		opacity: 0.65;
		font-size: 1rem;
	}
	.button.header-search-button:hover {
		opacity: 1;
	}
	.image-slider {
		padding-right: 0px;
		padding-left: 0px;
	}
	.slider-caption p {
		margin-right: 100px;
		margin-left: 100px;
		font-size: 15px;
		line-height: 24px;
	}
	.footer-heading {
		margin-top: 0rem;
		text-align: left;
	}
	.footer-right-col-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		margin-top: 1.5rem;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}
	.footer-section.footer-container {
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.social-media-link-block {
		-webkit-transition-property: all;
		transition-property: all;
		-webkit-transition-duration: 300ms;
		transition-duration: 300ms;
	}
	.social-media-link-block:hover {
		border-radius: 0%;
	}
	.social-media-link-block.first-social-link {
		margin-left: 0rem;
	}
	.footer-nav-wrapper {
		width: 100%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.footer-address {
		width: 100%;
		min-width: 0px;
		margin-top: 0.5rem;
		margin-bottom: 20px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.footer-nav-item {
		margin-bottom: 0.35rem;
	}
	.connect-with-us {
		width: 100%;
		margin-top: 2rem;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-top: 1px solid hsla(0, 0%, 100%, 0.4);
	}
	.social-outer-wrap {
		width: 100%;
	}
	.social-media-wrapper {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}
	.google-translate-outer-wrap {
		width: 50%;
	}
	footer .footer {
		padding-right: 10%;
		padding-left: 10%;
	}
	.signoff-left {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 90%;
		margin-right: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		margin-top: 0;
	}
	.container-3 {
		max-width: 991px;
	}
	.container-3.signoff-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 0;
		padding-bottom: 3rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: left;
	}
	.signoff-links-wrap {
		margin-left: 0rem;
	}
	.copyright-paragraph {
		margin-bottom: 0.5rem;
	}
	.div-block-6 {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
	.heading-2 {
		margin-top: 20px;
		margin-left: 100px;
	}
	.button-3 {
		margin-right: 100px;
		margin-left: 100px;
	}
	.about-us {
		padding-right: 100px;
		padding-left: 100px;
	}
	.paragraph-3 {
		font-size: 25px;
	}
	.white-heading {
		font-size: 40px;
		line-height: 50px;
	}
	.nav-link-2-dropdown {
		display: block;
		padding-bottom: 10px;
		padding-left: 30px;
		float: none;
		color: #fff;
		text-align: left;
	}
	.about-us-div {
		width: 80%;
	}
	.mobilesearchicon {
		position: absolute;
		left: auto;
		top: 35px;
		right: 60px;
		bottom: 0%;
		display: block;
		width: auto;
		height: auto;
		margin-top: 3px;
		margin-right: 40px;
		background-color: transparent;
		cursor: pointer;
	}
	.image-12 {
		width: 25px;
		height: 25px;
		margin-top: -23px;
		opacity: 1;
	}
	.form-block-2 {
		margin-top: 5px;
	}
	.form {
		height: 30px;
	}
	.text-field {
		width: 400px;
		height: 30px;
		margin-right: auto;
		margin-left: auto;
		border-radius: 4px;
		font-family: Lato, sans-serif;
		text-align: center;
	}
	.mobilesearchblock-2 {
		position: absolute;
		left: 0px;
		top: 100px;
		z-index: 1;
		display: none;
		overflow: visible;
		width: 100%;
		height: 40px;
		margin-top: -24px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: #004e59;
		-webkit-transition: all 300ms ease;
		transition: all 300ms ease;
	}
	.link-block {
		margin-top: 4px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.news-div {
		width: 48%;
		max-width: none;
		margin-bottom: 20px;
		padding-right: 0px;
	}
	.quick-links-container {
		width: 48%;
		max-width: none;
	}
	.div-block-32 {
		width: 100%;
	}
	.quick-links-footer {
		width: 60%;
		min-width: 0px;
		margin-top: 0.5rem;
		margin-left: 0px;
	}
	.div-block-34 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}
	.footer-heading-copy {
		margin-top: 0rem;
		text-align: left;
	}
	.connect-with-us-heading {
		margin-top: 0rem;
		text-align: left;
	}
	.main-content-wrapper {
		width: 100%;
		padding-top: 0vw;
	}
	.nav-link-3 {
		z-index: 140;
		display: block;
		width: 100%;
		margin-right: 0px;
		margin-bottom: 1px;
		padding: 1.25em 1.375rem;
		float: left;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		color: #fff;
		font-size: 1.125rem;
		font-weight: 400;
		text-align: left;
		letter-spacing: 0.02em;
		text-transform: none;
	}
	.nav-link-3:hover {
		background-color: transparent;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		color: #c9c6c4;
	}
	.nav-link-3.w--current {
		background-color: rgba(0, 0, 0, 0.3);
		background-image: none;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), inset 5px 0 0 0 #721926;
		color: #fff;
		text-align: left;
	}
	.nav-link-3.w--current:hover {
		background-image: none;
		color: hsla(0, 0%, 100%, 0.75);
	}
	.nav-link-3.two-part-mobile {
		width: 79.75%;
		float: left;
		background-image: none;
	}
	.nav-link-3.two-part-mobile:hover {
		color: hsla(0, 0%, 100%, 0.75);
	}
	.nav-link-3.mobile-list-back {
		background-color: rgba(0, 0, 0, 0.25);
		-webkit-transition: all 350ms ease;
		transition: all 350ms ease;
		color: #fff;
		cursor: pointer;
	}
	.nav-link-3.mobile-list-back:hover {
		color: #fff;
	}
	.two-part-button-toggle {
		width: 20%;
		margin-bottom: 1px;
		padding-top: 1.25em;
		padding-bottom: 1.25em;
		float: right;
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		background-color: rgba(0, 0, 0, 0.15);
		background-image: url("/20191128115800/assets/images/5dc584151d55e44ceb7b766d_icon_arrow2_right_wht_50.svg");
		background-position: 50% 50%;
		background-size: 1em 1em;
		background-repeat: no-repeat;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), -1px 0 0 0 hsla(0, 0%, 100%, 0.25);
		-webkit-transition: all 350ms ease;
		transition: all 350ms ease;
		color: transparent;
		font-size: 0.875rem;
		line-height: 2rem;
		text-align: center;
		cursor: pointer;
	}
	.two-part-button-toggle:hover {
		background-color: rgba(0, 0, 0, 0.15);
	}
	.two-part-button-wrap {
		position: static;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: none;
		float: left;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}
	.two-part-drop-list-back-arrow {
		display: inline-block;
		min-width: 0.75em;
		margin-top: -1px;
		margin-right: 0.25em;
		float: left;
		background-image: url("/20191128115800/assets/images/5dc584151d55e417977b766f_icon_arrow2_left_wht_50.svg");
		background-position: 0px 55%;
		background-size: 12px 12px;
		background-repeat: no-repeat;
		color: transparent;
		text-align: center;
	}
	.inside-page-content-wrap-no-sidebars {
		margin-right: 0%;
		margin-left: 0%;
	}
	.two-part-button-list {
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 150;
		display: block;
		width: 100%;
		margin-left: 100%;
		padding-bottom: 100vh;
		border-top: 1px none #21272e;
		background-color: #494848;
	}
	.breadcrumb-list {
		margin-top: 0rem;
		border-bottom: 1px solid #e9ece3;
	}
	.container {
		padding-right: 20px;
		padding-left: 20px;
	}
	.left-nav-list-content-wrap {
		overflow: hidden;
		height: 0px;
	}
	.inside-page-content-wrap-no-sidebars-2 {
		margin-right: 0%;
		margin-left: 0%;
	}
	.left-nav-list {
		margin-bottom: 1.5em;
	}
	.right-sidebar-section-wrap {
		margin-left: 0em;
		padding-right: 0px;
		padding-left: 0px;
	}
	.right-sidebar-column {
		margin-top: 3rem;
	}
	.contact-us,
	.footer-link {
		-webkit-align-self: center;
		-ms-grid-row-align: center;
		-ms-flex-item-align: center;
		align-self: center;
	}
}

@media (max-width: 767px) {
	.slider {
		height: 450px;
	}
	.slide {
		background-position: 50% 0%;
		background-size: auto 80%;
	}
	.div-block-2 {
		height: auto;
		margin-top: 0px;
	}
	.left-arrow {
		margin-left: 0px;
	}
	.right-arrow {
		margin-right: 0px;
	}
	.topnavigationholder {
		display: none;
	}
	.menu-button {
		right: 0%;
		padding-top: 30px;
	}
	.navbar-2 {
		height: 90px;
		margin-top: 0px;
	}
	.brand {
		left: 1%;
		width: 250px;
		height: auto;
		margin-top: 0px;
		padding-top: 15px;
	}
	.nav-menu {
		margin-top: 90px;
	}
	.main-nav-link.mobile-duplicate {
		width: 84%;
		padding-left: 1rem;
	}
	.header-search-container {
		display: none;
	}
	.image-3 {
		margin-top: 0px;
	}
	.content-boxes {
		padding-right: 20px;
		padding-left: 20px;
		width: 100%;
	}
	.header-search-wrap {
		display: none;
		padding-bottom: 1px;
	}
	.form-field.header-search-field {
		height: 3.25rem;
		font-size: 1em;
	}
	.button.header-search-button {
		width: 15%;
		height: 3.25rem;
		font-size: 1.125rem;
	}
	.image-slider {
		padding-right: 0px;
		padding-left: 0px;
	}
	.slider-caption p {
		margin-right: 60px;
		margin-left: 60px;
	}
	.footer-heading {
		text-align: center;
	}
	.footer-right-col-wrap {
		width: 100%;
		margin-top: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-top: 1px none hsla(0, 0%, 100%, 0.4);
	}
	.footer-section.footer-container {
		padding-right: 40px;
		padding-left: 40px;
	}
	.footer-nav-list {
		margin-top: 0.55rem;
		margin-left: 0rem;
	}
	.social-media-link-block {
		margin-right: 0.75rem;
	}
	.footer-nav-wrapper {
		width: 80%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.footer-address {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 35%;
		min-width: 200px;
		padding-right: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.footer-nav-list-link {
		margin-top: 0.25rem;
		margin-left: 0rem;
	}
	.footer-nav-list-link:hover {
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}
	.footer-nav-item {
		margin-top: 0rem;
	}
	.connect-with-us {
		padding-top: 0;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-top-style: solid;
	}
	.social-outer-wrap {
		width: 100%;
		margin-top: 1.4rem;
		text-align: center;
	}
	.social-media-wrapper {
		margin-bottom: 0rem;
		float: none;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
	.google-translate-outer-wrap {
		width: 100%;
	}
	.signoff-left {
		width: 100%;
		margin-top: 0.5rem;
		text-align: center;
	}
	.container-3 {
		max-width: 767px;
	}
	.container-3.signoff-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 1rem;
		padding-bottom: 1.5rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: left;
	}
	.signoff-links-wrap {
		float: none;
		text-align: center;
	}
	.copyright-paragraph {
		margin-right: 0.5rem;
		margin-bottom: 0.75rem;
		float: none;
		text-align: left;
	}
	.signoff-link {
		margin-right: 0.75rem;
		margin-bottom: 0.5rem;
	}
	.signoff-section {
		padding-top: 1px;
		padding-right: 0.25rem;
		padding-left: 0.25rem;
	}
	.copyright-wrap {
		float: none;
		text-align: center;
	}
	.heading-2 {
		margin-right: 60px;
		margin-bottom: 0px;
		margin-left: 60px;
		font-size: 20px;
		line-height: 25px;
	}
	.button-3 {
		margin-left: 60px;
		font-size: 10px;
	}
	.about-us {
		padding: 100px 40px;
	}
	.paragraph-3 {
		font-size: 15px;
		line-height: 20px;
	}
	.white-heading {
		font-size: 30px;
	}
	.about-us-div {
		width: 100%;
		padding-top: 40px;
		padding-bottom: 40px;
		padding-left: 40px;
	}
	.mobilesearchicon {
		position: absolute;
		top: -10px;
		right: 92px;
		display: block;
		width: 20px;
		height: 20px;
		margin-top: 46px;
		margin-right: 1px;
	}
	.image-12 {
		opacity: 1;
	}
	.mobilesearchblock-2 {
		top: 64px;
		display: none;
		margin-top: 21px;
		opacity: 1;
	}
	.slide-2 {
		background-position: 50% 0%;
		background-size: auto 75%;
	}
	.link-block {
		margin-top: 15px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.footer-link {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}
	.quick-links-list {
		width: 50%;
		margin-top: 0.55rem;
		margin-left: 0rem;
	}
	.div-block-32 a:hover,
	.quick-links:hover {
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}
	.news-div {
		width: 100%;
		padding-bottom: 0;
	}
	.quick-links-container {
		width: 100%;
	}
	.div-block-32 p {
		flex-basis: 33%;
	}
	.div-block-32 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		margin-bottom: 20px;
		margin-top: 10px;
	}
	.quick-links-footer {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		min-width: 200px;
		padding-right: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.div-block-34 {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.footer-heading-copy {
		text-align: center;
	}
	.connect-with-us-heading {
		text-align: center;
	}
	.contact-us {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}
	.main-content-wrapper {
		overflow: visible;
		padding-top: 0vw;
	}
	.main-content-wrapper.no-sidebars {
		margin-right: 10px;
		margin-left: 10px;
	}
	.nav-link-3 {
		font-size: 1rem;
	}
	.nav-link-3.two-part-mobile {
		width: 79.75%;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.15);
	}
	.two-part-button-toggle {
		background-color: rgba(0, 0, 0, 0.25);
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.15), -1px 0 0 0 hsla(0, 0%, 100%, 0.15);
		line-height: 1.6875rem;
	}
	.breadcrumb-list-item {
		font-size: 0.6875em;
	}
	.container {
		padding-right: 10px;
		padding-left: 10px;
	}
	.inside-page-content-wrap-no-sidebars-2 {
		margin-right: 10px;
		margin-left: 10px;
	}
	.intro-text-style-2 {
		font-size: 1.125em;
	}
	.right-sidebar-section-wrap {
		margin-left: 0rem;
	}
	.right-sidebar-column {
		margin-top: 1.5rem;
		padding-top: 1.5rem;
	}
	.breadcrumb-list-item-2 {
		font-size: 0.6875em;
	}
	.div-block-30 {
		position: relative;
	}
	.inside-row {
		padding-left: 20px;
		padding-right: 20px;
	}
	.div-block-33 {
		width: 100%;
		margin: 0;
	}
}

@media (max-width: 479px) {
	.slide {
		background-position: 50% 0%;
		background-size: auto 54%;
	}
	.div-block-2 {
		height: auto;
	}
	.left-arrow {
		width: 40px;
		margin-left: -10px;
	}
	.topnavigationholder {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}
	.menu-button {
		padding-top: 18px;
	}
	.menu-button.current.open {
		padding-top: 22px;
		padding-right: 10px;
	}
	.navbar-2 {
		height: 75px;
	}
	.brand {
		width: 150px;
		height: 50px;
		margin-top: 19px;
		padding-top: 0px;
	}
	.icon-2 {
		margin-top: 0px;
		padding-top: 0px;
		font-size: 30px;
	}
	.nav-menu {
		margin-top: 75px;
	}
	.main-nav-link.mobile-duplicate {
		width: 80%;
	}
	.header-search-container {
		display: none;
		width: 100%;
	}
	.image-3 {
		width: auto;
		max-width: 200px;
	}
	.content-boxes {
		padding-right: 10px;
		padding-left: 10px;
	}
	.form-field.header-search-field {
		height: 3rem;
	}
	.button.header-search-button {
		width: 20%;
		height: 3rem;
	}
	.image-slider {
		padding-right: 0px;
		padding-left: 0px;
	}
	.slider-caption p {
		margin-right: 40px;
		margin-left: 40px;
		font-size: 12px;
		line-height: 20px;
	}
	.footer-right-col-wrap {
		width: 100%;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.footer-section.footer-container {
		padding-right: 10px;
		padding-left: 10px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		padding-bottom: 0.5rem;
	}
	.footer-nav-list {
		margin-left: 0rem;
	}
	.footer-nav-wrapper {
		margin-right: 20px;
		margin-left: 20px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.footer-address {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		min-width: 0px;
		padding-right: 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.footer-nav-list-link {
		width: 100%;
		margin-left: 0rem;
		font-size: 14px;
	}
	.footer-nav-item {
		margin-bottom: 0.5rem;
	}
	.connect-with-us {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.container-3 {
		max-width: 479px;
	}
	.container-3.signoff-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 0rem;
		padding: 0.5rem 0rem 1rem;
	}
	.signoff-links-wrap {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}
	.copyright-paragraph {
		margin-right: 0.25rem;
		margin-bottom: 0rem;
		text-align: center;
	}
	.signoff-link {
		display: inline-block;
	}
	.signoff-section {
		padding-right: 1rem;
		padding-left: 1rem;
	}
	.div-block-6 {
		width: auto;
		margin-left: 0px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}
	.heading-2 {
		margin: 10px 40px 0px;
		font-size: 20px;
	}
	.button-3 {
		margin-left: 40px;
		padding: 5px 10px;
		font-size: 10px;
	}
	.paragraph-3 {
		font-size: 20px;
		line-height: 25px;
	}
	.white-heading {
		font-size: 30px;
		line-height: 35px;
	}
	.mobilesearchicon {
		top: -10px;
		right: 3.75rem;
		margin-top: 36px;
	}
	.image-12 {
		margin-top: -4px;
		font-size: 18px;
	}
	.text-field {
		width: 250px;
	}
	.mobilesearchblock-2 {
		top: 87.9943px;
		display: none;
		margin-top: -15px;
	}
	.slide-2 {
		background-size: auto 62%;
	}
	.body {
		overflow: hidden;
	}
	.link-block {
		margin-top: 20px;
	}
	.quick-links-list {
		margin-left: 0rem;
	}
	.content-box-img-container {
		height: 150px;
	}
	.newsevents-img {
		margin-top: 0px;
	}
	.quick-links-footer {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		min-width: 0px;
		padding-right: 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.div-block-34 {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
	.nav-link-3 {
		font-size: 0.9375rem;
	}
	.nav-link-3.two-part-mobile {
		width: 79%;
	}
	.two-part-button-toggle {
		font-size: 0.8125rem;
	}
	.div-block-32 {
		display: block;
	}

	.div-block-32 p {
		margin: 0;
		padding-top: 10px;
		padding-bottom: 10px;
	}
}

/* IE11 fixes */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.child-ul::before {
		background-position: -50px 100%;
	}
	:last-of-type.navlinkholder .child-ul::before {
		background-position: calc(100% + 57px) 100%;
	}
	.navbar-2 {
		position: fixed;
		width: 100%;
	}
	.inside-row,
	.image-slider {
		margin-top: 138px;
	}
	.inside-page-content {
		min-height: calc( 100vh - 484px + 138px );
	}
}

/* Phone links */
.phone-mobile {
	display: none;
}

@media (max-width: 991px) {
	.phone-mobile {
		display: inline-block;
	}

	.phone-desktop {
		display: none;
	}
}


/* Wait Times */
.content-boxes.wait-times {
	padding-top: 0;
	padding-bottom: 0;
}

.wait-time-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 20px;
	padding-bottom: 20px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	width: 100%;
	align-items: center;
}

.wait-time-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 200%;
	max-width: 1300px;
	margin-right: 0px;
	margin-left: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-content: stretch;
	-ms-flex-line-pack: stretch;
	align-content: stretch;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
}

.wait-time-container.inside-page {
	margin-right: 0px;
	margin-left: 0px;
}

.wait-time-inner-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	padding: 40px 0px 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 10px none #004e59;
	border-radius: 0px;
	background-color: #1b6c77;
}

.wait-time-inner-content._2 {
	margin-top: 0rem;
	padding: 40px 0px 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	border: 1px none #000;
}

.wait-time-inner-content._2.inside-page {
	padding-top: 10px;
	padding-bottom: 0px;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: transparent;
}

.display-text {
	margin: 0px 0px 0px 0rem;
	padding-left: 0px;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 15px;
	line-height: 25px;
	font-weight: 400;
	text-align: center;
}

.display-text.time {
	padding: 0px;
	color: #fff;
	font-size: 25px;
	font-weight: 700;
}

.display-text.time.yellow {
	margin-top: 0.65rem;
	margin-bottom: 0.65rem;
	padding: 0.5rem 1rem;
	color: #ffda28;
}

.display-text.time.yellow.inside-information {
	margin-top: 0rem;
	margin-bottom: 0.25rem;
}

.display-text.notice {
	font-size: 13px;
	line-height: 20px;
	font-style: italic;
	text-align: center;
}

.display-text.notice._2 {
	margin-right: 0rem;
	margin-left: 0px;
	font-size: 13px;
}

.display-heading {
	width: 100%;
	margin-top: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
	border-style: none;
	border-width: 1px 1px 8px;
	border-color: #000;
	background-color: transparent;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 18px;
	line-height: 30px;
	text-align: center;
}

.display-heading.hospital {
	font-family: Lato, sans-serif;
	font-size: 20px;
	line-height: 25px;
	font-weight: 400;
	text-align: left;
}

.display-heading.hospital.centered {
	margin-bottom: 0.5rem;
	font-size: 23px;
	text-align: center;
}

.display-heading._2 {
	margin-bottom: 1rem;
	font-size: 32px;
	font-weight: 400;
}

.display-heading._2.inside-page {
	margin-bottom: 0rem;
	color: #1a3354;
	font-size: 24px;
	font-weight: 700;
}

.timer-icon {
	width: 23px;
	height: 23px;
	margin-right: 1rem;
}

.timer-icon.big {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 45px;
	height: 45px;
	margin-bottom: 1.25rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	grid-auto-columns: 1fr;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
}

.timer-icon.big.inside-page {
	width: 32px;
	height: 35px;
	margin-right: 0.5rem;
	margin-bottom: 0rem;
}

.clock-cb-wrapper {
	padding: 1rem 1.65rem;
	border-radius: 20px;
	background-color: #004e59;
}

.clock-cb-wrapper.wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 1rem;
	padding: 1rem 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 10px;
	background-color: #004049;
}

.clock-cb-wrapper.wrapper._2 {
	margin-top: 1rem;
	margin-bottom: 2rem;
	padding-right: 4rem;
	padding-left: 4rem;
}

.clock-cb-wrapper.wrapper._1 {
	margin-right: 0rem;
	margin-bottom: 0rem;
	padding-right: 1.65rem;
	padding-left: 1.65rem;
	background-color: #004e59;
}

.clock-cb-wrapper.wrapper._1.inside-page {
	padding: 1.25rem 4rem;
}

.clock-cb-wrapper.wrapper._2 {
	display: none;
}

.wait-time-heading {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	margin-bottom: 1rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.wait-time-heading.inside-page {
	margin-bottom: 0.65rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}

.wait-time-heading.home-page {
	margin-right: 2.5rem;
}

.clock-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	justify-content: center;
}

.cb-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-right: 140px;
	padding-bottom: 40px;
	padding-left: 140px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.cb-wrap.inside-page {
	width: auto;
	padding-right: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.heading-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.announcement-cb-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-top: 0px;
	margin-bottom: 0px;
	padding-right: 140px;
	padding-left: 140px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: hsla(0, 0%, 100%, 0.08);
}

.announcement-cb-wrap.inside-page {
	margin-top: 20px;
	padding-right: 20px;
	padding-left: 20px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	background-color: #ededed;
}

.display-text-2 {
	margin: 0px 0px 0px 0rem;
	padding-left: 0px;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 15px;
	line-height: 25px;
	font-weight: 400;
}

.display-text-2 p {
	font-size: 15px;
	line-height: 25px;
	font-weight: 400;
	margin-bottom: 0;
}

.display-text-2.black {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	color: #000;
	text-align: left;
}

.display-text-2.yellow {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	color: #fff0ca;
	text-align: center;
}


.announcement-cb-wrap.inside-page {
	margin-top: 20px;
	padding-right: 20px;
	padding-left: 20px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	background-color: #ededed;
}

@media (max-width: 991px) {
	.timer-icon.big {
		margin-right: 0rem;
	}

	.wait-time-section {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		width: 100%;
	}

	.content-boxes.wait-times {
		padding-left: 0;
		padding-right: 0;
	}

	.cb-wrap {
		padding-right: 20px;
		padding-left: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.cb-wrap.inside-page {
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.heading-wrap {
		margin-right: 0rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.wait-time-heading.home-page {
		margin-right: 0rem;
	}

	.announcement-cb-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-right: 20px;
		padding-left: 20px;
	}

	.display-text-2.black {
		margin: 0.65rem 0px;
	}

	.display-text-2.yellow {
		margin: 0.65rem 0px;
	}
}

@media (max-width: 767px) {
	.display-text-2 {
		margin-right: 60px;
		margin-left: 60px;
	}

	.display-text-2.black {
		text-align: left;
	}

	.display-text-2.yellow {
		text-align: center;
	}
}

@media (max-width: 479px) {
	.display-text {
		margin-right: 0px;
		margin-left: 0px;
		font-size: 14px;
		line-height: 20px;
	}

	.display-text.time.yellow {
		font-size: 23px;
	}

	.display-text.notice {
		margin-right: 0px;
		margin-left: 0px;
		font-size: 13px;
		line-height: 15px;
	}

	.display-text.notice._2 {
		font-size: 13px;
	}

	.display-text-2 p {
		font-size: 12px;
		line-height: 20px;
	}

	.display-text-2.black p {
		font-size: 13px;
		text-align: left;
	}

	.display-text-2.yellow p {
		font-size: 13px;
	}

	.cb-wrap.inside-page {
		width: 100%;
	}

	.clock-wrap {
		width: 100%;
	}

	.clock-cb-wrapper.wrapper._1.inside-page {
		padding-left: 1rem;
		padding-right: 1rem;
		width: 100%;
	}
}